var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"election"},[(_vm.candidateLaunched)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper",style:([
           _vm.$mq === 'desktop' || _vm.$mq === 'tablet' ? { 'flex-direction': 'row' } : { 'flex-direction': 'column' },
         ])},[_c('div',{staticClass:"election-container"},[_c('div',{staticClass:"header-tooltip"},[_c('div',{staticClass:"header header-member"},[_vm._v("DAO Committee Members")]),_c('img',{attrs:{"src":require("@/assets/input_question_icon@3x.png"),"alt":"","width":"16","height":"16"}}),_c('div',{class:[
            _vm.$mq === 'desktop'
              ? 'tooltip1'
              : _vm.$mq === 'tablet'
                ? 'tooltip1-tablet'
                : 'tooltip1-mobile'
          ]},[(_vm.$mq === 'mobile')?_c('img',{staticStyle:{"margin-right":"85px"},attrs:{"src":require("@/assets/tooltip-mobile.png"),"alt":"","width":"6","height":"4"}}):_c('img',{staticStyle:{"margin-top":"30px"},attrs:{"src":require("@/assets/arrow-tooltip.png"),"alt":"","width":"4","height":"6"}}),_c('div',{staticClass:"tooltip-content"},[_vm._v(" DAO candidates with the most staked TON are eligible to serve as DAO committee members and vote on DAO agendas. Currently, there are three members. ")])])]),_vm._l((_vm.membersArray),function(memberIndex){return _c('card-member',{key:memberIndex,attrs:{"member-index":memberIndex}})}),_c('div',{staticClass:"header-tooltip header-tooltip-nonmember"},[_c('div',{staticClass:"header"},[_vm._v("Other DAO Candidates")]),_c('img',{attrs:{"src":require("@/assets/input_question_icon@3x.png"),"alt":"","width":"16","height":"16"}}),_c('div',{class:[
            _vm.$mq === 'desktop'
              ? 'tooltip2'
              : _vm.$mq === 'tablet'
                ? 'tooltip2-tablet'
                : 'tooltip2-mobile'
          ]},[(_vm.$mq === 'mobile')?_c('img',{staticStyle:{"margin-right":"85px"},attrs:{"src":require("@/assets/tooltip-mobile.png"),"alt":"","width":"6","height":"4"}}):_c('img',{staticStyle:{"margin-top":"25px"},attrs:{"src":require("@/assets/arrow-tooltip.png"),"alt":"","width":"4","height":"6"}}),_c('div',{staticClass:"tooltip-content"},[_vm._v(" An operator registered as a DAO candidate who has staked a minimum of 1,000.1 TON (non-withdrawable). ")])])]),_vm._l((_vm.sortedNonmembersByVotes),function(nonmember){return _c('card-nonmember',{key:nonmember.candidateContract,attrs:{"candidate":nonmember}})})],2),_c('div',{staticClass:"card-container",style:([
             _vm.$mq === 'desktop' || _vm.$mq === 'tablet' ?
               {
                 'width': '378px',
                 'margin-left': '15px',
               } :
               {
                 'width': '100%',
               },
           ])},[_c('card-resource')],1)])]):_c('div',[_c('loading')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }