import { render, staticRenderFns } from "./MarkdownViewer.vue?vue&type=template&id=409b5be1&scoped=true"
import script from "./MarkdownViewer.vue?vue&type=script&lang=js"
export * from "./MarkdownViewer.vue?vue&type=script&lang=js"
import style0 from "./MarkdownViewer.vue?vue&type=style&index=0&id=409b5be1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409b5be1",
  null
  
)

export default component.exports